import React, { useEffect, useState, useRef } from "react";
import nose from "../../assets/assest/nose.png";
import palate from "../../assets/assest/palate.png";
import finish from "../../assets/assest/finish.png"
import backbutton from '../../assets/assest/back.jpeg';
import home from '../../assets/assest/home.png';
import * as htmlToImage from 'html-to-image';
import instagram from '../../assets/assest/instagram.png';
import facebook from '../../assets/assest/facebook.png';
import twitter from '../../assets/assest/twitter.png';
import DescriptionComponent from "../description.js";
import whatsapp from '../../assets/assest/whatsapp.png';
import logo from '../../assets/assest/default.png';
import logo1 from "../../assets/assest/logo.png";
import {base_url} from '../../constant.js';
import html2canvas from 'html2canvas';
import background1 from "../../assets/assest/bg.png";
import background2 from "../../assets/assest/Theme1.png";
import background3 from "../../assets/assest/Theme2.png";
import newmale from '../../assets/assest/member_level_frames/newbie.png'
import newfemale from '../../assets/assest/member_level_frames/newbie_female.png';
import moderatorMale from '../../assets/assest/member_level_frames/moderator_male.png';
import moderatorFemale from '../../assets/assest/member_level_frames/moderator_female.png';
import expertMale from '../../assets/assest/member_level_frames/expert_male.png';
import expertFemale from '../../assets/assest/member_level_frames/expert_female.png';
import connoisseurMale from '../../assets/assest/member_level_frames/connoisseur_male.png';
import connoisseurFemale from '../../assets/assest/member_level_frames/connoisseur_female.png';
import enthusiastMale from '../../assets/assest/member_level_frames/enthusiast_male.png';
import enthusiastFemale from '../../assets/assest/member_level_frames/enthusiast_female.png';
import "./whiskyclub.css";
import { useLocation,useNavigate } from 'react-router-dom';
import Loader from "../loader/loader.js";
import Modal from 'react-modal';

const WhiskyClub = () => {
  const [apidata,setApiData]= useState(null);
  const [isLoading, setIsLoading]= useState(true);
  const elementRef = useRef(null);
  const location = useLocation();
  const navigate =useNavigate();
  const title = 'Smac Flavour Folio';
  const [capturedImage,setcapturedImage] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { report_id,selectedImage,useProfile,uploadedImage,useReview,profileFrameUrl} = location.state || {};
  const [hasOnTheNoseRating,sethasOnTheNoseRating] = useState(null);
  const [hasOnThePalateRating,sethasOnThePalateRating] = useState(null);
  const [hasFinishOption,sethasFinishOption] = useState(null);
  const [hasAnyRating,sethasAnyRating] = useState(null);
  const [shareUrl,setShareUrl] =useState('');
  const bgImages = [background1,background2,background3];
  const [reviewImage,setReviewImage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [frameUrl,setUrl] = useState(null);
  const [myProfile,setMyProfile]= useState(null);
  // const [popupTitle, setPopupTitle] = useState('');
  const togglePopup = (data, title) => {
    setPopupData(data);
    // setPopupTitle(title);
    setShowPopup(!showPopup);
    
  }
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    setIsLoading(true);
    localStorage.setItem('whiskey_id',null);
    localStorage.removeItem('whiskey_id');
    async function fetchData()
    {
      //fetch report
      if(useProfile)
        {
          console.log("first");
          setUrl(profileFrameUrl);
         setMyProfile(useProfile?1:0);
         await updateDesign();
    }
    else
    {
      console.log("second");
      await getProfile();
    }
    }
    fetchData();
}, []);


const updateDesign=async()=>{
  //update report
  try {
    const formData = new FormData();
    formData.append('report_id', report_id);
    formData.append('design_type', "0");
    formData.append('my_profile', useProfile?1:0);
    formData.append('use_review', useReview?1:0);
    formData.append('color_code', selectedImage);
    console.log("selected",selectedImage);
    const response = await fetch(base_url+'/updateDesign',{
    method:"POST",
    body:formData,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
  });
    const data = await response.json();
    if(data!=null)
    {
    // console.log("data ",data);
    await getProfile();
    }
  } catch (error) {
    setIsLoading(false);
    console.error(error);
  }
};

const getProfile=async()=>{
  try {
    const formData = new FormData();
    formData.append('report_id', report_id);
    const response = await fetch(base_url+'/reportDetail',{
    method:"POST",
    body:formData,
  });
    const data = await response.json();
    if(data!=null)
    {
      const frameur = getProfileImage(data.data.report.user.gender, getNextLevel("15"));
      setUrl(frameur);
      setMyProfile(data.data.report.my_profile);
      if(data.data.report.image)
      {
        setReviewImage(data.data.report.image);
      }
      else
      {
        setReviewImage(uploadedImage);
      }
      console.log("profile data",data.data.report.my_profile);
      if(data.data.report.design_id)
        {
      if(data.data.report.designImage)
        {
          setcapturedImage(data.data.report.designImage?data.data.report.designImage.url??'':'');
        }
        setApiData(data);
        if(data.data.report.on_the_nose_rating !== "null")
          {
            sethasOnTheNoseRating(true);
          }
        if(data.data.report.on_the_palate_ratings !== "null")
          {
            sethasOnThePalateRating(true);
          }
        if(data.data.report.finish_option !== "null")
          {
             sethasFinishOption(true);
          }
        if(data.data.report.finish_option !== "null" || data.data.report.on_the_palate_ratings !== "null" || data.data.report.on_the_nose_rating !== "null")
          {
               sethasAnyRating(true);
          }
        setIsLoading(false);
        setShareUrl(window.location.origin+"/design?design_id="+data.data.report.design_id);
        }
        else
        {
          await updateDesign();
          setIsLoading(false);
        }
    }
  } catch (error) {
    setIsLoading(false);
    console.error(error);
  }
};

const handleGoBack = () => {
  window.history.back();
};
const goToHomepage = () =>{
  console.log("hiii")
  navigate("/profileDashboard",{state:{report_id:report_id}})
}
const convertRatingToStar = (rating) => {
  let stars = '';
  for (let i = 0; i < rating; i++) {
    stars += '★';
  }
  return stars;
};
const convertRatingToStars = (rating) => {
  const totalStars = 10;
  const filledStars = Math.round(rating);
  const starsArray = Array.from({ length: totalStars }, (v, i) => i < filledStars ? '★' : '☆');
  return starsArray.join('');
};

const fullDescription = apidata ?(apidata.data.report.description) : '';
      const continuationText = "Read more";
      const ReadLess = "Read Less"
      const words = fullDescription.split(' ');
      let maxWords = 30;
      let visibleWords = words.slice(0, maxWords).join(' ');
    
      while (visibleWords.length + continuationText.length > 150 && maxWords > 0) {
        maxWords -= 1;
        visibleWords = words.slice(0, maxWords).join(' ');
      }
    
   
      const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
      };
        //capture

    const shareFacebook=async()=>{
      if(capturedImage)
        {
          // await captureAndDownloadImage();
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,'_blank');
        }
        else
        {
          // await captureAndDownloadImage();
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,'_blank');
        }
      
    };

    // const shareInsta=async()=>{
    //   if(capturedImage)
    //     {
    //       window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
    //     }
    //     else
    //     {
    //       await captureAndDownloadImage();
    //      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
    //     }
      
    // };

    const shareWhatsapp=async()=>{
      if(capturedImage)
        {
          window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
        }
        else
        {
          // await captureAndDownloadImage();
          window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
        }
    };

    const shareTwitter=async()=>{
      const shareText = "Check out this whisky rating on SMAC Flavour Folio!";
      if(capturedImage)
        {
          // await captureAndDownloadImage();
          window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`, '_blank');
        }
        else
        {
          // await captureAndDownloadImage();
          window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`, '_blank');
        }
      
    };


    const getNextLevel = (totalPointsEarned) => {
      if (totalPointsEarned >= 500) {
          return "Moderator";
      } else if (totalPointsEarned >= 301) {
          return "Expert";
      } else if (totalPointsEarned >= 151) {
          return "Connoisseur";
      } else if (totalPointsEarned >= 51) {
          return "Enthusiast";
      } else {
          return "Beginner";
      }
  };

    const getProfileImage = (gender, level) => {
      if (level === "Moderator") {
        return gender === "male" ? moderatorMale : moderatorFemale;
      } else if (level === "Expert") {
        return gender === "male" ? expertMale : expertFemale;
      } else if (level === "Connoisseur") {
        return gender === "male" ? connoisseurMale : connoisseurFemale;
      } else if (level === "Enthusiast") {
        return gender === "male" ? enthusiastMale : enthusiastFemale;
      } else {
        return gender === "male" ? newmale : newfemale;
      }
    };
    
    // const captureAndDownloadImage = async () => {
    //         if (elementRef.current === null) {
    //             return;
    //         }
    
    //         try {
    //         //   const canvas = await html2canvas(elementRef.current, {
    //         //     useCORS: true,
    //         //     allowTaint: true,
    //         // });
    //         // const dataUrl = canvas.toDataURL('image/jpeg', 0.95);
    //         const dataUrl = await htmlToImage.toPng(elementRef.current, { cacheBust: true });
    //         const link = document.createElement('a');
    //        link.href = dataUrl;
    //        link.download = 'screenshot.png';
    //        link.click();
    //         const blob = await fetch(dataUrl).then((res) => res.blob());
    //         const file = new File([blob], `${report_id}.jpg`, { type: blob.type });
    //         try {
    //           const formData = new FormData();
    //           formData.append('designImage', file);
    //           formData.append('report_id', report_id);
    //           console.log('design image',file);
    //           console.log('report_id',report_id);
    //           const response = await fetch(base_url+'/updateDesign',{
    //           method:"POST",
    //           body:formData,
    //           headers: {
    //             'Authorization': 'Bearer ' + localStorage.getItem('token'),
    //           },
    //         });
    //           const data = await response.json();
    //           if(data!=null)
    //           {
    //               setIsLoading(false);
    //               setcapturedImage(data.data.designImage.url);
    //           }
    //         } catch (error) {
    //           //  setIsLoading(false);
    //         }
    //         } catch (error) {
    //             console.error('Error capturing and converting to image', error);
    //         }
    //     };
        const hasOnTheNoseFlavours = apidata && apidata.data.onthenose.length > 0;
      const hasOnThePalateFlavours = apidata && apidata.data.onthepalate.length > 0;
return (
  <div>
  {isLoading ? (
    <Loader />
  ) :
  <div
  className="flavour-container container-fluid"
  style={{
    background: apidata && apidata.data && apidata.data.report && apidata.data.report.color_code
    ? `url(${bgImages[apidata.data.report.color_code]})`
    : '#ffffff',
  fontFamily: 'Lato, sans-serif',
  width: '100%',
  // borderRadius: '20px',
  zIndex:'0'
  }}
>
  <div className="row">
{useProfile!=null?null:<div style={{ position: 'absolute', top: '10px',zIndex:1}}>
         <button
            type="button"
            className="btn-link"
            style={{
              color: 'white',
              border: 'none', 
             background: 'transparent',
             cursor: 'pointer', 
            }}
            >
            <div className='gradient-border'>
              <img src={backbutton} style={{ width: '50px', height: '50px',borderRadius:'13px' }} alt="Back"  onClick={()=>handleGoBack()} />
              </div>
          </button>
  </div>}
  <div style={{ position: 'relative' }}>
  <div style={{ float: 'right', margin: '10px' }}>
    <button
      type="button"
      className="btn-link"
      style={{
        color: "black",
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        marginRight: '-10px',
        zIndex: 9999, // Ensure a high z-index
        position: 'relative', // Explicitly set position
      }}
      onClick={() => goToHomepage()}
    >
      <div className="gradient-border" style={{ position: 'relative', zIndex: 10 }}>
        <img
          src={home}
          alt="Home"
          style={{
            cursor: 'pointer',
            width: '50px',
            height: '50px',
            borderRadius: '13px',
            zIndex: 5, // Ensure the image also has a high z-index
          }}
        />
      </div>
    </button>
  </div>
</div>

  </div>

<div ref={elementRef} >
  
   <div className=" mt-3 mb-3">
   <div className="row">
    <div className="col-6">
      {myProfile==1 ? (
          
          <div style={{ position: 'relative', zIndex:'0' }}>
            <img src={frameUrl} alt="Profile Frame" className="profile-frame-img"/>
            <img
              src={apidata && apidata.data.report.user.profile.url}
              // style={{ width: '85%', marginTop: '-2px' }}
              alt="Profile"
              className="profile"
            />
          </div>
        ) : (
          <div style={{ position: 'relative' }}>
            <img src={logo1} className="img-fluid mx-auto d-block" alt="Logo" />
          </div>
        )}
    </div>
    <div className="col-6">
      <div className="ardmore" style={{height:'100%',zIndex:'0' }}>
        <div className="ardmore-section d-flex align-items-center justify-content-center">
          {reviewImage ? (
              <img src={reviewImage} className="img-review img-thumbnail" alt="Uploaded"  style={{zIndex:'0' }}/>
          ) : (
              apidata && apidata.data.report.whisky && apidata.data.report.whisky.icon && (
                  <img src={apidata.data.report.whisky.icon.url} className="img-review img-thumbnail" alt="Whisky Icon" />
              )
          )}
        </div>   
      </div>
    </div>
    </div>
  </div>

  <div className="ardmore">
  <div className="nose-palate-section">

    <div className="row">
    <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
        <div className="nose-container text-center">
            <img src={nose} alt="Nose Image" className="large-images mx-auto d-block" 
                style={{ width: '80px', height: '80px', borderRadius: '56px' }}
            />
            <p className="text-center">Aromatics</p>
        </div>
    </div>
      <div className="col-8 col-md-8">
      <div className="flavours">
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1" style={{textAlign:'center'}}>
            {hasOnTheNoseRating ? (
                        hasOnTheNoseFlavours ? (
                          <>
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px' }}>
                  {apidata.data.onthenose.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.preview_url} alt={`On the Nose Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthenose.length < 6 && apidata.data.randomonthenose.slice(0, Math.max(0, 6 - apidata.data.onthenose.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthenose.length}>
                      <img src={aroma.icon.preview_url} alt={`On the Nose Image ${index + apidata.data.onthenose.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
                <button
                              className="smacid-btn v-all"
                              onClick={() => togglePopup([...apidata.data.onthenose])}
                            >
                              View All
                            </button>
                          </>
              ): (
                <p className="text-center ">No flavours selected</p>
              )
            ) : (
              <p className="text-center">No rating available</p>

              )}
              {/* //   <button className="smacid-btn v-all" 
              //   onClick={() => togglePopup([...apidata.data.onthenose])}>
              //   View All
              // </button> */}

            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
    <div className="row">
      <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
          <div className="nose-container text-center">
              <img src={palate} alt="Nose Image" className="large-images mx-auto d-block" 
                  style={{ width: '80px', height: '80px', borderRadius: '56px'}}
              />
              <p className="text-center">On the Palate</p>
          </div>
      </div>
      <div className="col-8 col-md-8">
      <div className="flavours">
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1" style={{textAlign:'center'}}>
            {hasOnThePalateRating ?(
            hasOnThePalateFlavours ? (
              <>
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px'}}>
                  {apidata.data.onthepalate.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.preview_url} alt={`On the Palate Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthepalate.length < 6 && apidata.data.randomonthepalate.slice(0, Math.max(0, 6 - apidata.data.onthepalate.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthepalate.length}>
                      <img src={aroma.icon.preview_url} alt={`On the Palate Image ${index + apidata.data.onthepalate.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
                <button className="smacid-btn v-all" 
                onClick={() => togglePopup([...apidata.data.onthepalate])}>
                View All
              </button>
              </>
                 ) : (
                  <p className="text-center">No flavours selected</p>
                )
                      ) : (
                        <p className="text-center">No rating available</p>
              )}
              
             
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>

  {/* <div className="images-section">
    <div className="flavours" style={{ marginBottom: '20px' }}>
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1">
              {apidata && (
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px' }}>
                  {apidata.data.onthenose.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.url} alt={`On the Nose Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthenose.length < 6 && apidata.data.randomonthenose.slice(0, Math.max(0, 6 - apidata.data.onthenose.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthenose.length}>
                      <img src={aroma.icon.url} alt={`On the Nose Image ${index + apidata.data.onthenose.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
              )}
              <button className="view-al-btn" style={{ marginTop: '10px' }} 
              onClick={() => togglePopup([...apidata.data.onthenose])}>
                View All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flavours">
      <div className="circular-layout">
        <div className="im-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="row-container">
            <div className="im-container1">
              {apidata && (
                <div className="image-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridGap: '4px'}}>
                  {apidata.data.onthepalate.slice(0, 6).map((aroma, index) => (
                    <div className="small-image-container" key={index}>
                      <img src={aroma.icon.url} alt={`On the Palate Image ${index + 1}`} className="small-images" />
                    </div>
                  ))}
                  {apidata.data.onthepalate.length < 6 && apidata.data.randomonthepalate.slice(0, Math.max(0, 6 - apidata.data.onthepalate.length)).map((aroma, index) => (
                    <div className="small-image-container" key={index + apidata.data.onthepalate.length}>
                      <img src={aroma.icon.url} alt={`On the Palate Image ${index + apidata.data.onthepalate.length + 1}`} className="small-images" />
                    </div>
                  ))}
                </div>
              )}
              <button className="view-al-btn" style={{ marginTop: '10px' }}
              onClick={() => togglePopup([...apidata.data.onthepalate])}>
                View All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</div>

     
      
          <div className=" justify-content-center align-items-center w-72">
          {hasAnyRating && (
        <div className="form-group align-items-center" style={{ marginTop:'10px',height: '114px', paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px', paddingTop: '10px', borderRadius: '20px', border: '2px solid black' }}>
          {hasOnTheNoseRating && (
            <>
              <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '-25px', height: '37px' }}>
                <div className="on_the_nose_img">
                <h6 style={{ fontSize: '12px', fontWeight:'bold' ,marginTop:'8px'}}>Aromatics</h6>
                  {/* <img src={nose} style={{ maxHeight: '23px', maxWidth: '23px' }} /> */}
                </div>
                <div className="on_the_nose_rating">
                  <p>
                    <span className="stars" style={{ fontSize: '16px' }}>
                      {convertRatingToStars(apidata.data.report.on_the_nose_rating)}
                    </span>
                    <span style={{ color: 'black', fontSize: '16px' }}>
                      {apidata.data.report.on_the_nose_rating}.0
                    </span>
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}

          {hasOnThePalateRating && (
            <>
              <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '-26px', marginTop: '-18px', height: '37px' }}>
                <div className="on_the_nose_img">
                <h6 style={{ fontSize: '12px', fontWeight:'bold' ,marginTop:'8px'}}>On the Palate</h6>
                  {/* <img src={palate} style={{ maxHeight: '23px', maxWidth: '23px' }} /> */}
                </div>
                <div className="on_the_nose_rating">
                  <p>
                    <span className="stars" style={{ fontSize: '16px' }}>
                      {convertRatingToStars(apidata.data.report.on_the_palate_ratings)}
                    </span>
                    <span style={{ color: 'black', fontSize: '16px' }}>
                      {apidata.data.report.on_the_palate_ratings}.0
                    </span>
                  </p>
                </div>
              </div>
              <hr />
            </>
          )}

          {hasFinishOption && (
            <>
              <div className="on_the_nose" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '-13px', height: '38px' }}>
                <div className="on_the_nose_img">
                <h6 style={{ fontSize: '12px', fontWeight:'bold' ,marginTop:'8px'}}>Finish</h6>
                  {/* <img src={finish} style={{ maxHeight: '23px', maxWidth: '23px' }} /> */}
                </div>
                <div className="on_the_nose_rating">
                  <p>
                    <span className="stars">
                      {convertRatingToStar(apidata.data.report.finish_option)}
                    </span>
                    <span style={{ color: 'black', fontSize: '12px' }}>
                      {apidata.data.report.finish_option}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      )}

        </div>
        <div className="flavour-content" style={{color:'rgb(10, 37, 60)',padding:'7px'}}>
      <b style={{textDecoration:'underline'}}>{apidata?apidata.data.report.whisky.whiskey_name:''}</b>
        <div className="report-content">
        <p>
            <span className="stars">{convertRatingToStars(apidata?apidata.data.report.ratings:'')}</span>
            <span style={{ color: 'black' }}>{apidata?apidata.data.report.ratings:''}.0</span>
          </p>
        </div>
        {(apidata &&
            apidata.data &&
            apidata.data.report &&
            apidata.data.report.use_review === 1)? <p className="description" style={{ textAlign: 'justify', fontSize: '14px', color: 'rgb(10, 37, 60)', marginTop: '-10px', lineHeight: '1.5' }}>
              {apidata && apidata.data.report.review}</p> : (
           <DescriptionComponent apidata={apidata} />
      )}

          </div>
      </div>
          {/* <div className="text-center">
          <button className="smacid-btn" style={{ width:'100px',height:'35px',backgroundColor:'#0A253C',borderRadius:'40px',alignItems:'center'}} >
          <span><b style={{fontSize:'16px',}}>SHARE!</b></span>
          </button>
          </div> */}
          <div className="text-center">
          <button className="smacid-btn" style={{ width:'70%',height:'40px',backgroundColor:'white',borderRadius:'40px',marginTop:'20px', marginBottom: '20px'}}>
           <div className="icons" style={{display:'flex'}}>
           <img src={facebook} height={{height:'40px'}} onClick={()=>shareFacebook()}/>
           <img src={whatsapp} height={{height:'40px'}} onClick={()=>shareWhatsapp()}/>
           <img src={twitter} height={{height:'40px'}} onClick={()=>shareTwitter()}/>
           {/* <img src={instagram} height={{height:'40px'}} onClick={()=>shareInsta()}/> */}
           </div>
          </button>
          </div>
          </div>}
          <Modal
  isOpen={showPopup}
  onRequestClose={() => setShowPopup(false)}
  contentLabel="View All Popup"
  style={{
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: '10px',
      padding: '20px',
      maxWidth: '340px',
      width: '90%',
      maxHeight: '80vh', // Set a max height to restrict content overflow
      backgroundColor: '#f9f9f9',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      overflowY: 'auto', // Use 'auto' for better behavior
    },
  }}
>
  <button onClick={() => setShowPopup(false)} className="close-button" style={{
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  }}>
    &times;
  </button>
  <div className="popup-grid" style={{ zIndex: 10 }}>
    {popupData.map((item, index) => (
      <div key={index} className="popup-item" style={{ marginBottom: '10px' }}>
        <img
          src={item.icon.url}
          alt={item.name}
          className="popup-image"
          style={{
            maxWidth: '100px',
            maxHeight: '100px',
            marginBottom: '5px',
          }}
        />
        <p style={{ fontSize: '12px', fontWeight: 'bold' }}>{item.name}</p>
      </div>
    ))}
  </div>
</Modal>

   </div>
  );
};

export default WhiskyClub;
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './pages/Services/AuthContext';
import './routeDesign.css';

const PrivateRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem('token');
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  const allowedRoutes = [
    '/smacid',
    '/profileDashboard',
    '/profilepage',
    '/',
    '/dashboard',
    '/addWhiskyForm',
    '/editProfile',
    '/choosewhisky',
    '/loyalitypoints',
    '/overallreview',
  ];

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timeout = localStorage.getItem('expirationTime') - new Date().getTime();
    const whiskeyId = localStorage.getItem('whiskey_id');

    // Logout after token expires
    setTimeout(() => {
      logout();
    }, timeout);

    // Show popup if whiskey_id exists and current route is in allowedRoutes
    if (whiskeyId && allowedRoutes.includes(location.pathname) && whiskeyId!=null) {
      console.log('Whiskey data',whiskeyId);
      setShowPopup(true);
    }
  }, [logout, location.pathname]);

  const handleContinue = () => {
    setShowPopup(false);
    navigate('/Mainpage');  // Or replace '/Mainpage' with any route you want to redirect to
  };

  const handleStay = () => {
    localStorage.removeItem('whiskey_id');
    localStorage.removeItem('on_the_nose');
    localStorage.removeItem('on_the_palate');
    localStorage.removeItem('on_the_nose_rating');
    localStorage.removeItem('on_the_palate_ratings');
    localStorage.removeItem('ratings');
    localStorage.removeItem('review');
    localStorage.removeItem('rating');
    localStorage.removeItem('reviee');
    setShowPopup(false);
  };

  if (!isAuthenticated) {
    return <Navigate to="/smacid" />;
  }

  return (
    <>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <div className="popup-header">
              {/* <h2>Resume?</h2> */}
            </div>
            <div className="popup-body">
              <p>Welcome back! Your session has been successfully resumed. Do you want to continue where you left off.</p>
            </div>
            <div className="popup-footer">
            <button className="popup-button stay" onClick={handleStay}>
                Back Home
              </button>
              <button className="popup-button continue" onClick={handleContinue}>
                Continue Review
              </button>
             
            </div>
          </div>
        </div>
      )}
      {!showPopup && element}
    </>
  );
};

export default PrivateRoute;
